import React from "react";

function article_1() {
	return {
		date: "7 Feb 2024",
		title: "Retrieval-Augmented Generation (RAG) in AI",
		description:
			"RAG is revolutionizing AI by combining retrieval-based search with generative models. Learn how it enhances accuracy and contextual understanding.",
		keywords: [
			"Retrieval-Augmented Generation",
			"RAG AI",
			"AI Retrieval Systems",
			"LLMs and RAG",
			"Tushar Anand",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid blue;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						Retrieval-Augmented Generation (RAG) is an advanced AI technique that combines **information retrieval** with **large language models (LLMs)** to improve response accuracy and relevance.  
						Unlike traditional generative models, RAG fetches **external knowledge** from databases or the web to provide **fact-based** and **context-aware** responses.  
						<br />
						Use cases of RAG include **chatbots, research assistants, and AI-driven search engines**.
					</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "7 Feb 2024",
		title: "Using OpenAI APIs to Build AI-Powered Projects",
		description:
			"Learn how developers can leverage OpenAI APIs to create chatbots, text summarization tools, and more. A step-by-step guide to integrating AI into your projects.",
		keywords: [
			"OpenAI API",
			"ChatGPT Integration",
			"Building with AI",
			"AI-powered applications",
			"Tushar Anand",
		],
		style: ``,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						OpenAI provides a range of APIs that allow developers to integrate **AI functionalities** into their applications.  
						Some popular APIs include:  
						- **GPT-4** for natural language understanding and text generation  
						- **DALL·E** for AI-generated images  
						- **Whisper** for speech-to-text conversion  
						- **Embeddings API** for advanced search and recommendations  
						<br />
						Developers can use OpenAI APIs to build **chatbots, summarization tools, AI-assisted coding platforms**, and more.
					</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_3() {
	return {
		date: "7 Feb 2024",
		title: "AI in Web Development: Transforming User Experience",
		description:
			"AI is redefining web development by enhancing personalization, automation, and efficiency. Discover the latest trends in AI-powered web applications.",
		keywords: [
			"AI in Web Development",
			"Web Automation",
			"AI UI/UX",
			"Machine Learning Websites",
			"Tushar Anand",
		],
		style: ``,
		body: (
			<React.Fragment>
				<h1>Content of article 3</h1>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1, article_2, article_3];

export default myArticles;

