const INFO = {
	main: {
		title: "Reactfolio by Tushar Anand",
		name: "Tushar Anand",
		email: "tusharanand517@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/TusharGeek",
		linkedin: "https://www.linkedin.com/in/tushar-anand-317a25221/",
	},

	homepage: {
		title: "Full-stack web developer and AI enthusiast.",
		description:
			"I am a full-stack developer with expertise in React, Node.js, Express, and MySQL. I specialize in building high-performance web applications with scalable architectures. Passionate about writing clean, maintainable code and always eager to explore new technologies.",
	},

	about: {
		title: "I’m Tushar Anand. I love building scalable web applications.",
		description:
			"Over the years, I've worked on multiple projects, ranging from CRM systems to AI-integrated platforms. Many of my projects are open-source and available for developers to explore and contribute. If you’re interested in any of my work, feel free to check out the repositories and suggest improvements. Collaboration is key to growth, and I always appreciate feedback and new ideas.",
	},

	articles: {
		title: "Exploring innovations in full-stack development and AI integration.",
		description:
			"A collection of my thoughts on software engineering, best practices, and emerging technologies in the industry.",
	},

	projects: [
		{
			title: "Scholar Simplify",
			description:
				"A research platform integrating OpenAI’s ChatGPT API, allowing users to access a database of academic papers within a chat interface.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://scholarsimplify.com",
		},

		{
			title: "AAA Deliver",
			description:
				"An e-commerce platform for hardware materials, built using React, Node.js, and MySQL. Includes secure authentication with JWT and Google Auth.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/typescript/typescript.png",
			linkText: "View Project",
			link: "https://aaadeliver.ca",
		},

		{
			title: "GGCA CRM",
			description:
				"A CRM platform for managing student and staff data at GJCEI, Guru Nanak Dev University. Built with React, Node.js, and MySQL.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/typescript/typescript.png",
			linkText: "View Project",
			link: "#",
		},

		{
			title: "AVP Immigration",
			description:
				"A React-based static website for AVP Immigration",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://avp-eta.vercel.app/",
		},

		{
			title: "Assignments Simplify",
			description:
				"Complete platform for Assignment Simplify, that manages task and allot it to writers",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://app.assignmentssimplify.com",
		},
	],
};

export default INFO;
